import axios from 'axios'

function listCouponCode(payload, wl_code) {
  let wlCode = axios.defaults.headers.wl_code
  if(wl_code){
      axios.defaults.headers.wl_code = wl_code
  }
  const response = axios.get('booking/list-booking-coupon-code', {
      params:payload
  })
  axios.defaults.headers.wl_code = wlCode
  return response
}

function activeDeactiveCouponCode(id) {
  return axios.put(`booking/active-deactive-booking-coupon-code/${id}`)
}

export default {
  listCouponCode,
  activeDeactiveCouponCode,
}
