<template>
  <div>
    <confirm-disable
      ref="disableModal"
      :msg="disableMsg"
      @on-submit="switchCouponCodeStatus"
      @on-hide="resetStatus"
    />
    <!-- <loader :is-visible="isLoading" /> -->
    <!-- Table Container Cards -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="8">
            <b-row>
              <b-col v-if="currentWhitelabel == 1" cols="12" md="6" class="mb-md-0 mb-2">
                <label>Filter by WhiteLabel</label>
                <v-select
                  v-model="wl_code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="whitelabelList"
                  class="w-100"
                  :reduce="(val) => val.value"
                  placeholder="Select Whitelabel"
                  :clearable="false"
                />
              </b-col>

              <!-- Search -->
              <b-col cols="12" :md="currentWhitelabel == 1 ? '6' : '6'" :class="wl_code == 1 ? 'mb-md-0 mb-2 mt-2' : 'mb-md-0 mb-2'">
                <!-- <b-col cols="12" md='6' class='mb-md-0 mb-2 mt-2'> -->
                <div :class="currentWhitelabel == 1 ? 'd-flex align-items-bottom justify-content-end' : 'd-flex align-items-right justify-content-end'">
                  <!-- <div class='d-flex align-items-bottom justify-content-end'> -->
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(coupon_code)="data">
          <div style="width: 130px">
            <span
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.view
              "
              style="cursor: pointer; font-weight: 600"
              @click="
                navigateToBookingDetail(
                  data.item.booking_quote_id,
                  //data.item.bookings ? data.item.bookings[0].bookingMode : ''
                  '',
                  wl_code
                )
              "
              >{{
                data.item.coupon_code
              }}
            </span>
          </div>
        </template>

        <template #cell(local_booking_id)="data">
          <div style="width: 100px">
            {{
              data.item.local_booking_id
            }}
          </div>
        </template>

        <!-- Column Status -->
        <template #cell(status)="data">
          <div>
            <b-form-checkbox
              v-model="data.item.is_coupon_used"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="
                openDisableModal(
                  data.item.bookingCouponCode_id,
                  data.item.is_coupon_used,
                  data.item.coupon_code
                )
              "
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="checkAccess.Edit || checkAccess.delete" 
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{ name: 'coupon-code-edit', params: { id: data.item.bookingCouponCode_id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing
              {{ totalCouponCodes >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalCouponCodes ? totalCouponCodes : endIndex }} of
              {{ totalCouponCodes }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalCouponCodes"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  resConditionCheck,
  RoleEnum,
  WhiteLabel
} from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
import ConfirmDisable from "../../confirm-disable/ConfirmDisable.vue";
import CouponCodeService from "../../../services/coupon-code/couponCode.service";
import whitelabelService from "@/services/whitelabel/whitelabel.service";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ConfirmDelete,
    BFormCheckbox,
    ConfirmDisable,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      perPageOptions,
      perPage,
      totalCouponCodes: 0,
      isSortDirDesc: false,
      currentPage,
      disableMsg: "",
      deleteMsg: "",
      titleMsg: "",
      couponCodeId: "",
      checkAccess: {},
      checkLoginRole,
      RoleEnum,
      status2: "",
      items: [],
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      tableColumns: [
        { key: "coupon_code", sortable: true },
        { key: "local_booking_id" },
        { key: "status" },
      ],
      wl_code: axios.defaults.headers.wl_code,
      WhiteLabel,
      whitelabelList: [],
    };
  },
  computed:{
    currentWhitelabel(){
      return axios.defaults.headers.wl_code || 1
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.provider();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.provider();
      }
    },

    sortBy: {
      handler() {
        this.provider();
      }
    },

    searchQuery: {
      handler() {
        this.currentPage = 1;
        this.provider();
      }
    },

    isSortDirDesc: {
      handler() {
        this.provider();
      }
    },

    wl_code: {
      async handler(val) {
        if (val === null) {
          this.wl_code = 1;
        }
        this.provider();
      },
    },
  },
  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    // if (
    //   this.checkLoginRole() !== RoleEnum.SuperAdmin
    //   && !this.checkAccess.Edit
    // ) {
    //   this.tableColumns = [
    //     { key: "couponCode", label: "name", sortable: true },
    //     { key: "status" }
    //   ];
    // }
    this.provider();
    if (this.wl_code == 1) {
      await this.getWhitelabelList();
    }
  },
  methods: {
    provider() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy === "coupon_code" ? "couponCode" : '',
        search: this.searchQuery,
        wlCode: this.wl_code
      };
      CouponCodeService.listCouponCode(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data
            this.totalCouponCodes = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
          console.log("res",res);
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalCouponCodes = 0;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },
    // openModal(id, name) {
    //   this.titleMsg = deleteConfirmMsg;
    //   this.deleteMsg = `Are you sure you want to delete ${name} language ?`;
    //   this.languageId = id;
    //   this.$refs.modal.show();
    // },
    openDisableModal(id, status, coupon_code) {
      this.couponCodeId = id;
      this.status2 = !status;
      this.$refs.disableModal.show();
      this.disableMsg = !status
        ? `Are you sure you want to deactivate ${coupon_code} coupon code ?`
        : `Are you sure you want to activate ${coupon_code} coupon code ?`;
    },
    resetStatus() {
      const foundIdex = this.items.findIndex(
        item => item.bookingCouponCode_id === this.couponCodeId
      );
      this.$set(this.items[foundIdex], "is_coupon_used", this.status2);
    },
 
    switchCouponCodeStatus(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.disableModal.loading = true;
      if (this.couponCodeId) {
        CouponCodeService.activeDeactiveCouponCode(this.couponCodeId)
          .then(res => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            if (resConditionCheck(res.status)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.disableModal.loading = false;
            this.$refs.disableModal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            const foundIdex = this.items.findIndex(
              item => item.bookingCouponCode_id === this.couponCodeId
            );
            this.$set(this.items[foundIdex], "isActive", this.status2);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    },

    navigateToBookingDetail(id, mode, wl_code) {
      if (mode === 2) {
        this.$router.push({
          name: "new-booking-details",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "booking-details",
          params: { id },
          query: { page: this.currentPage, rows: this.perPage, wl_code: wl_code },
        });
      }
    },

    getWhitelabelList() {
      this.isLoaded = false;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: "ASC",
        order_by: "name",
      };

      whitelabelService
        .getWhitelableList(payload)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.isLoaded = true;
            this.whitelabelList = res.data.data.map((item) => {
              // if(!item.isDeleted) {
              return {
                label: item.name,
                value: item.id,
              };
              // }
            });
            this.whitelabelList.push({ label: "Beontrips", value: 1 });
          }
        })
        .catch((error) => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>